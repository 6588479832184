























































































































































































import router from "@/router";
import { Component, Vue, Watch } from "vue-property-decorator";
import { PaginatedList } from "@/api/models/common/paginatedList";
import {
  BButton,
  BFormInput,
  BFormGroup,
  BForm,
  BSpinner,
  BPagination,
  BFormFile,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min, max } from "@validations";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import EditIcon from "@/components/icons/EditIcon.vue";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import {
  ExtraFilterCreateUpdateModel,
  ExtraFilter,
} from "@/api/models/rapportActivites/rapportActivite";
import { BFormSelect, BFormSelectOption, BFormCheckbox } from "bootstrap-vue";
import { TypeColonneEnum } from "@/api/models/enums/typeColonne";
import { TypeGraphEnum } from "@/api/models/enums/typeGraph";

@Component({
  components: {
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BSpinner,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BFormSelectOption,
    DeleteIcon,
    EditIcon,
    SearchableVueSelect,
    BFormFile,
  },
})
export default class ListFiltresRA extends Vue {
  required = required;
  min = min;
  max = max;
  typeColonneEnum = TypeColonneEnum;
  typeGraphEnum = TypeGraphEnum;

  mainLoading: boolean = false;
  currentPage = 1;
  pageSize = 10;

  selected: any = null;

  typesColonneOptions = [
    { value: TypeColonneEnum.Boolean, text: "Booléen" },
    { value: TypeColonneEnum.Numeric, text: "Numérique" },
    { value: TypeColonneEnum.Text, text: "Texte" },
    { value: TypeColonneEnum.Date, text: "Date" },
  ];

  typesGraphOptions = [
    { value: TypeGraphEnum.Individuel, text: "Individuel" },
    { value: TypeGraphEnum.Collectif, text: "Collectif" },
  ];

  listFilters: PaginatedList<ExtraFilter> = new PaginatedList<ExtraFilter>();

  newFilter: ExtraFilterCreateUpdateModel = {};

  async created() {
    await this.loadFilters();
  }

  @Watch("currentPage")
  async currentPageChange() {
    await this.loadFilters();
  }

  async loadFilters() {
    this.mainLoading = true;
    await this.$http.ressifnet.rapportActivites
      .extraFilters(this.currentPage, this.pageSize)
      .then((response: PaginatedList<ExtraFilter>) => {
        this.listFilters = response;
        this.mainLoading = false;
      });
  }

  getTypeGraph(filter: ExtraFilter) {
    return filter.typeGraph === 0 ? "Individuel" : "Collectif";
  }

  goDetails(filtreId: any): any {
    router.push({
      name: "admin-filtre-ra-details",
      params: { filtreId: filtreId },
    });
  }

  async confirmCreate(event: any) {
    event.preventDefault();

    (this.$refs.createFormValidation as InstanceType<typeof ValidationObserver>)
      .validate()
      .then(async (isSuccess: boolean) => {
        if (isSuccess) {
          var data = {
            extraFilterCreateModel: this.newFilter,
          };
          await this.$http.ressifnet.rapportActivites
            .createExtraFilter(data)
            .then(
              async (response: any) => {
                this.$bvModal.hide("modal-post-template");
                successAlert.fire({
                  title: "Création d'un filtre de rapport d'activité",
                  text: "Création effectuée avec succès",
                });

                router.push({
                  name: "admin-filtre-ra-details",
                  params: { filtreId: response },
                });
              },
              (error: any) => {
                if (error.response.data.errors) {
                  errorAlert.fire({
                    text: error.response.data.errors[
                      Object.keys(error.response.data.errors)[0]
                    ],
                  });
                } else {
                  this.$bvModal.hide("modal-post-template");
                  errorAlert.fire({
                    text: error.message,
                  });
                }
              },
            );
        }
      });
  }

  async deleteFiltre(filtreId: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce filtre ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(filtreId);
        } else {
          return;
        }
      });
  }

  async confirmDelete(filtreId: string) {
    await this.$http.ressifnet.rapportActivites
      .deleteExtraFilter(filtreId)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'un filtre de rapport d'activité",
            text: "Suppression effectuée avec succès",
          });

          await this.loadFilters();
        },
        (error: any) => {
          errorAlert.fire({
            text: error.message,
          });
        },
      );
  }
}
